/* eslint-disable */
import $ from 'jquery';
import '@fancyapps/fancybox';
import 'jquery-mask-plugin';
import AOS from 'aos';
import Swiper from 'swiper';
import 'jquery-parallax.js';

window.$ = $;
window.jQuery = $;

AOS.init({
	once: true
});

// scrollto and menu
$(() => {
	// $('.header__menu__links a, .footer__menu__links a, a.links_scroll').on('click', function () {
	// 	$('#headerMenu').removeClass('open').addClass('hide');
	// 	$('html, body').animate({
	// 		scrollTop: $($(this).attr('href')).offset().top - 130
	// 	}, 2000);
	// });

	$('[scrollto]').on('click', function (e) {
		e.preventDefault();

		$('html, body').animate({
			scrollTop: $($(this).attr('scrollto')).offset().top
		}, 2000);

		return false;
	});
});

// $(() => {
//     $('.free-modal').on('click', function (e) {
// 		e.preventDefault();
		
//         $('#popup').removeClass('hide').addClass('show');
//         $('#freeForm').removeClass('hide').addClass('show');
//     })
//     $('.info-modal').on('click', function (e) {
//         e.preventDefault();
//         $('#popup').removeClass('hide').addClass('show');
//         $('#infoForm').removeClass('hide').addClass('show');
//     })
//     $('.popup-close').on('click', function (e) {
//         e.preventDefault();
//         $('#popup').removeClass('show').addClass('hide');
//         $('.popup-form').removeClass('show').addClass('hide');
//     })
// })

// fix header
$(() => {
	if(window.matchMedia("max-width: 991px").matches) {
		$(window).on('scroll', function (e) {
			if (window.pageYOffset !== 0) {
				$('.header').addClass('fixed');
			} else {
				$('.header').removeClass('fixed');
			}
		});
	}
});

// Menu for mobile
$(() => {
	$('#openMenu').on('click', function (e) {
		e.preventDefault();
		$('#headerMenu').removeClass('hide').addClass('open');
	})
	$('#cross').on('click', function (e) {
		e.preventDefault();
		$('#headerMenu').removeClass('open').addClass('hide');
	})
});

// start block
$(() => {

	setInterval(() => {
		const curActive = $('.block-start_advantagers li.active');
		const nextActive = curActive.next();
		const count = $('.block-start_advantagers li').length;

		if (curActive.index() !== count - 1) {
			nextActive.trigger('click', { autoplay: true });
		} else {
			$('.block-start_advantagers li:first-child').trigger('click', { autoplay: true });
		}
	}, 3000);

	$('.block-start_advantagers li').on('click', function(e, params) {
		if (params === undefined) {
			clearInterval(intervalAutoplay);
		}
		e.preventDefault();
		$('.block-start_advantagers li').removeClass('active');
		$(this).addClass('active');
		
		return;
	});
	
});

// slider mockups
$(() => {
	if (window.matchMedia("(min-width: 991px)").matches) {
		const swiperMockups = new Swiper('.block-solution__slider .swiper-container', {
			direction: 'vertical'
		});
		let curActive = $('.block-solution_nav li a.active').parents('li');
		const countElems = $('.block-solution_nav li').length;
		const $navElem = $('.block-solution_nav');
		// mob
		const widthItem = 200; 

		const autoplayInterval = setInterval(() => {
			curActive = $('.block-solution_nav li a.active').parents('li');
			const nextActive = curActive.next();

			if (curActive.index() !== countElems - 1) {
				nextActive.find('a').trigger('click', { autoplay: true });
			} else {
				$('.block-solution_nav li:first-child a').trigger('click', { autoplay: true });
			}

			if (window.matchMedia("(max-width: 991px)").matches) {
				const nextPositionCoff = curActive.index() + 1 <= countElems - 1 ? curActive.index() + 1 : 0;
				$(document).find('.block-solution_nav.wrap').animate({ scrollLeft: widthItem * nextPositionCoff }, 800);
				// $(document).find('.block-solution_nav.wrap').scrollLeft(widthItem * nextPositionCoff);
			}
		}, 5000);
		
		$('.block-solution_nav a').on('click', function (e, params) {
			if (params === undefined) {
				clearInterval(autoplayInterval);
			}
			e.preventDefault();
			const slide = +$(this).data('slide');
			const text = $(this).data('text');
			$('.block-solution_nav a').removeClass('active');
			$(this).addClass('active');
			swiperMockups.forEach(function (swiperItem) {
				swiperItem.slideTo(slide);
			});
			$('.block-solution_text').text(text);
			return false;
		});
	} else {
		$('.block-solution_nav li').each(function() {
			const textActive = $(this).find('a').data('text');
			$(this).find('a').after(`<p>${textActive}</p>`);
			if ($(this).find('a').hasClass('active')) {
				$(this).addClass('active');
			}
		});

		$('.block-solution_nav li a').on('click', function(e) {
			e.preventDefault();
			$('.block-solution_nav li a').removeClass('active');
			$(this).addClass('active');
			$('.block-solution_nav li').removeClass('active');
			$(this).parents('li').addClass('active');
			return;
		});
	}
});

// slider results
$(() => {
	const swiper = new Swiper('.block-results .swiper-container', {
		autoplay: {
			delay: 5000,
		},
		loop: true,
		navigation: {
			nextEl: '.block-results .swiper-button-next',
			prevEl: '.block-results .swiper-button-prev',
		},
	});
});

// timeline
$(() => {
	const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const section = entry.target;
                if($(section).hasClass('block-why-we')) {
					const $timeline = $(section).find('.block-why-we__timeline');
					if(!$timeline.hasClass('animated')) {
						$timeline.addClass('animated');
						let indexItem = 0;
						$timeline.find('.block-why-we__timeline__item').eq(indexItem).addClass('active');
						const interval = setInterval(() => {
							if (indexItem === 3) clearInterval(interval);
							else {
								indexItem += 1;
								$timeline.find('.block-why-we__timeline__item').eq(indexItem).addClass('active');
							}
						}, 1000);
					}
				}
                observer.unobserve(section)
            }
        })
    }, options);

    const arr = document.querySelectorAll('section');
    arr.forEach(i => {
        observer.observe(i)
    });
});

// table scroll
$(() => {
	$('.block-how-cost_nav-mobile .next').on('click', function() {
		const widthCol = $('.block-how-cost__tariffs td:nth-child(2)').innerWidth();
		$('.block-how-cost .table-container').scrollLeft(widthCol);
	});
	$('.block-how-cost_nav-mobile .prev').on('click', function() {
		const widthCol = $('.block-how-cost__tariffs td:nth-child(2)').innerWidth();
		$('.block-how-cost .table-container').scrollLeft(-widthCol);
	});
	$('.block-need-site_nav-mobile .next').on('click', function() {
		const widthCol = $('.block-need-site__tariffs td:nth-child(2)').innerWidth();
		$('.block-need-site .table-container').scrollLeft(widthCol);
	});
	$('.block-need-site_nav-mobile .prev').on('click', function() {
		const widthCol = $('.block-how-cost__tariffs td:nth-child(2)').innerWidth();
		$('.block-need-site .table-container').scrollLeft(-widthCol);
	});
});

// popup 
$(() => {
    $('.free-modal').on('click', function (e) {
		e.preventDefault();
		const btnAction = $(this).data('action');
		$('input[name="btn-action"]').val(btnAction);
        $('#popup').removeClass('hide').addClass('show');
        $('#freeForm').removeClass('hide').addClass('show');
    });
    $('.popup-close').on('click', function (e) {
        e.preventDefault();
        $('#popup').removeClass('show').addClass('hide');
        $('.popup-form').removeClass('show').addClass('hide');
    });
});